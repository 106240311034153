const keyWords = [
  'react',
  'graphql',
  'javascript',
  'agency',
  'bournemouth',
  'react native',
  'node',
  'js',
  'design',
  'mobile development',
  'mobile apps',
  'mobile design',
  'web development',
  'web design',
  'web apps',
  'single page app',
  'humancrafted',
  'human crafted',
  'web design company',
  'mobile development company',
  'software development',
  'mitch clay',
]

export { keyWords }
